import { BigNumber } from '@ethersproject/bignumber'
import { Zero, WeiPerEther } from '@ethersproject/constants'

export function calculateLeftUnits(expiringUnits = [], _now: any) {
  const pastDays = Math.floor((Date.now() - parseInt(_now) * 1000) / 86400000)

  const lefted = expiringUnits.map((x, i, arr) => {
    return arr.slice(i).reduce((s, y) => s.add(y), Zero)
  })
  lefted.splice(0, pastDays)
  return [...lefted, Zero, ...Array(pastDays).fill(Zero)]
}

export function calculateTotalUnits(expiringUnits = [], _now: any) {
  const pastDays = Math.floor((Date.now() - parseInt(_now) * 1000) / 86400000)
  const lefted = expiringUnits.map((x) => x)
  lefted.splice(0, pastDays)
  return lefted.reduce((a: BigNumber, c: BigNumber) => a.add(c), Zero)
}

export function calculateUnderlyingEth(shares: BigNumber = Zero): BigNumber {
  const c1 = BigNumber.from('78125000')
  const c2 = BigNumber.from('149999843750000')

  return c1
    .mul(shares.mul(shares))
    .add(
      c2
        .mul(shares)
        .mul(WeiPerEther)
        .div(BigNumber.from('2'))
    )
    .div(WeiPerEther)
    .div(WeiPerEther)
}
