import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import Card from './Card'
import CardContent from './CardContent'
import Bold from './Bold'
import EtheremIcon from './EthereumIcon'
import ReactTooltip from 'react-tooltip'
import FirstPlace from '../assets/first-place.png'
import SecondPlace from '../assets/second-place.png'
import ThirdPlace from '../assets/third-place.png'
import Level1Badge from '../assets/levels/1.png'
import Level2Badge from '../assets/levels/2.png'
import Level3Badge from '../assets/levels/3.png'
import Level4Badge from '../assets/levels/4.png'
import Level5Badge from '../assets/levels/5.png'
import Level6Badge from '../assets/levels/6.png'
import Level7Badge from '../assets/levels/7.png'
import Level8Badge from '../assets/levels/8.png'
import Level9Badge from '../assets/levels/9.png'
import Level10Badge from '../assets/levels/10.png'

const badgeUrl = {
  1: Level1Badge,
  2: Level2Badge,
  3: Level3Badge,
  4: Level4Badge,
  5: Level5Badge,
  6: Level6Badge,
  7: Level7Badge,
  8: Level8Badge,
  9: Level9Badge,
  10: Level10Badge,
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-overflow: ellipsis;
  }

  th {
    font-size: 14px;
  }
`

const InlineCenter = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const Badge = styled.img`
  width: 48px;
  height: 48px;
  vertical-align: top;
`

type Agent = {
  agentId: string
  name: string
  level: string | number
  accumulatedRef: BigNumber
}

type LeaderboardProps = {
  agents: Agent[]
}

export default function Leaderboard(props: LeaderboardProps): JSX.Element {
  const { agents = [] } = props
  const { t } = useTranslation()

  const renderRank = (rank: number): React.ReactNode => {
    if (rank === 1) {
      return <img src={FirstPlace} alt='first-place' />
    } else if (rank === 2) {
      return <img src={SecondPlace} alt='second-place' />
    } else if (rank === 3) {
      return <img src={ThirdPlace} alt='third-place' />
    } else {
      return <Bold>{rank}</Bold>
    }
  }

  return (
    <Card>
      <CardContent>
        <Table>
          <thead>
            <tr>
              <th>{t('rank')}</th>
              <th>{t('name')}</th>
              <th>{t('level')}</th>
              <th>{t('bonus')}</th>
            </tr>
          </thead>
          <tbody>
            {agents.map((agent, index) => (
              <tr key={agent.agentId}>
                <td>
                  <InlineCenter>{renderRank(index + 1)}</InlineCenter>
                </td>
                <td>{agent.name}</td>
                <td>
                  <Badge
                    data-tip
                    data-for={agent.name}
                    src={(badgeUrl as any)[agent.level]}
                    alt={`level${agent.level}`}
                  />
                  <ReactTooltip id={agent.name} effect='solid'>
                    <span>{`level${agent.level}`}</span>
                  </ReactTooltip>
                </td>
                <td>
                  <InlineCenter>
                    {!+agent.accumulatedRef
                      ? '0.0000'
                      : ethers.utils
                          .formatEther(agent.accumulatedRef)
                          .slice(0, 6)}{' '}
                    <EtheremIcon />
                  </InlineCenter>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardContent>
    </Card>
  )
}
