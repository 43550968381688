import { NetworkConnector } from './NetworkConnector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const POLLING_INTERVAL = 8000

const RPC_URLS = {
  1: process.env.REACT_APP_ETHEREUM_PROVIDER || '',
}

export let defaultChainId = 1
const supportedChainIds = [1]

export const injected = new InjectedConnector({
  supportedChainIds,
})

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1] },
  defaultChainId,
})

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})
