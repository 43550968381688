import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Card from './Card'
import CardContent from './CardContent'
import Level1Badge from '../assets/levels/1.png'
import Level2Badge from '../assets/levels/2.png'
import Level3Badge from '../assets/levels/3.png'
import Level4Badge from '../assets/levels/4.png'
import Level5Badge from '../assets/levels/5.png'
import Level6Badge from '../assets/levels/6.png'
import Level7Badge from '../assets/levels/7.png'
import Level8Badge from '../assets/levels/8.png'
import Level9Badge from '../assets/levels/9.png'
import Level10Badge from '../assets/levels/10.png'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th, td {
    height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-overflow: ellipsis;
  }

  th {
    font-size: 14px;
  }
`

const BadgeWrapper = styled.div`
  max-width: 96px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  > span {
    margin-left: 4px;
  }
`

const Badge = styled.img`
  width: 48px;
  height: 48px;
`

export default function LevelTable(): JSX.Element {
  const { t } = useTranslation()

  const levels = [
    { name: t('bronze'), badgeUrl: Level1Badge, experience: '0', profitSharing: '7%' },
    { name: t('silver'), badgeUrl: Level2Badge, experience: '0.073891', profitSharing: '8%' },
    { name: t('gold'), badgeUrl: Level3Badge, experience: '0.200855', profitSharing: '9%' },
    { name: t('platinum'), badgeUrl: Level4Badge, experience: '0.545981', profitSharing: '10%' },
    { name: t('beryl'), badgeUrl: Level5Badge, experience: '1.484132', profitSharing: '11%' },
    { name: t('sapphire'), badgeUrl: Level6Badge, experience: '4.034288', profitSharing: '12%' },
    { name: t('ruby'), badgeUrl: Level7Badge, experience: '10.966332', profitSharing: '13%' },
    { name: t('emerald'), badgeUrl: Level8Badge, experience: '29.807980', profitSharing: '14%' },
    { name: t('diamond'), badgeUrl: Level9Badge, experience: '81.030839', profitSharing: '15%' },
    { name: t('blueDiamond'), badgeUrl: Level10Badge, experience: '220.264658', profitSharing: '16%' },
  ]

  return (
    <Card>
      <CardContent>
        <Table>
          <thead>
            <tr>
              <th>{t('level')}</th>
              <th>{t('experience')}</th>
              <th>{t('profitSharing')}</th>
            </tr>
          </thead>
          <tbody>
            {levels.map((level, index) => (
              <tr key={level.experience}>
                <td>
                  <BadgeWrapper>
                    <Badge src={level.badgeUrl} alt={`level${index + 1}`} />
                    <span>{level.name}</span>
                  </BadgeWrapper>
                </td>
                <td>{level.experience}</td>
                <td>{level.profitSharing}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardContent>
    </Card>
  )
}