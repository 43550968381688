import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
  ReactNode,
} from 'react'
import { useWeb3React } from '../hooks/ethereum'
import { ethers } from 'ethers'
import { safeAccess, isAddress, getContract, ChainId } from '../utils'
import { INSURANCE_ADDRESSES } from '../constants'
import INSURANCE_ABI from '../constants/abis/insurance.json'

type ProfileState = {}

type ProfileContext = any[]

const ProfileContext = createContext<ProfileContext | undefined>(undefined)

export function useProfileContext() {
  return useContext(ProfileContext)
}

const UPDATE = 'UPDATE'

function reducer(state: ProfileState, { type, payload }: { type: 'UPDATE', payload: any }): ProfileState {
  switch (type) {
    case UPDATE: {
      const { chainId, account, id, name, level, points, pointsMax } = payload

      return {
        ...state,
        [chainId]: {
          ...(safeAccess(state, [chainId]) || {}),
          [account]: {
            ...(safeAccess(state, [chainId, account]) || {}),
            id,
            name,
            level,
            points,
            pointsMax,
          },
        },
      }
    }
    default: {
      throw Error(`Unexpected action type in ProfileContext: ${type}`)
    }
  }
}

export default function Provider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback(
    (chainId, account, id, name, level, points, pointsMax) =>
      dispatch({
        type: UPDATE,
        payload: {
          chainId,
          account,
          id,
          name,
          level,
          points,
          pointsMax,
        },
      }),
    [],
  )
  const value = useMemo(() => [state, { update }], [state, update])

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export function useProfile(account: string | null | undefined) {
  const { chainId, library } = useWeb3React()
  const [state, { update }] = useProfileContext()
  const { id, name, level, points, pointsMax } =
    safeAccess(state, [(chainId as ChainId), (account as string)]) || {}

  const updateProfile = useCallback(async () => {
    if (
      (chainId || chainId === 0) &&
      isAddress(account) &&
      isAddress(INSURANCE_ADDRESSES[chainId as number]) &&
      library
    ) {
      const contract = getContract(
        INSURANCE_ADDRESSES[chainId],
        INSURANCE_ABI,
        library,
      )
      const player = await contract.player(account)
      const requirement = await contract.requirement(player.level)
      update(
        chainId,
        account,
        player.id,
        ethers.utils.parseBytes32String(player.name),
        player.level,
        player.accumulatedRef,
        requirement,
      )
    }
  }, [account, chainId, library, update])

  useEffect(() => {
    updateProfile()
  }, [updateProfile])

  return {
    account,
    id,
    name,
    level,
    points,
    pointsMax,
    refresh: updateProfile,
  }
}
