import React, { ReactNode } from 'react'
import {
  ThemeProvider as StyledComponentsThemeProvider,
  css,
  createGlobalStyle,
  DefaultTheme,
} from 'styled-components'
import backgroundImage from '../assets/background.svg'

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const mediaQuery: { [width in keyof typeof breakpoints]: typeof css } = Object.keys(breakpoints).reduce((accumulator, label) => {
  ;(accumulator as any)[label] = (a: any, b: any, c: any) => css`
    @media (min-width: ${(breakpoints as any)[label]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

const black = '#000000'
const white = '#FFFFFF'

const theme: DefaultTheme = {
  mediaQuery,
  colors: {
    black,
    white,
    gray: '#D8D8D8',
    primary: '#17a2b8',
    success: '#81c784',
    error: '#e33371',
    textColor: '#231536',
    backgroundColor: white,
    headerBackground: '#74ADBB',
    buttonBackground: '#14A0DE',
    buttonText: white,
    tabsBackground: '#74ADBB',
    indicator: '#CAF4FF',
    divider: 'rgba(0, 0, 0, 0.2)',
  },
}

export default function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  )
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  html {
    font-size: 16px;
    font-family: 'Lora', serif;
    font-variant: none;
    color: ${({ theme }) => theme.colors.textColor};
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    background-image: url(${backgroundImage});
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`

