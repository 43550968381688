import React, { Suspense, ReactNode, useEffect } from 'react'
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { SnackbarProvider } from 'notistack'
import { Web3Provider } from '@ethersproject/providers'

import InsuranceContextProvider, {
  Updater as InsuranceContextUpdater,
} from './contexts/insurance'
import ProfileContextProvider from './contexts/profile'
import ThreeBoxContextProvider from './contexts/3Box'
import * as MAGIC_BOOST from './utils/magicBoost'
import Home from './pages/Home'
import Referral from './pages/Referral'
import Web3Manager from './components/Web3Manager'
import ThemeProvider, { GlobalStyle } from './themes'
import { NetworkContextName } from './constants'

const Web3ReadOnlyProvider = createWeb3ReactRoot(NetworkContextName)

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 10000
  return library
}

type ContextProvidersProps = {
  children: ReactNode
}

function ContextProviders({ children }: ContextProvidersProps) {
  return (
    <InsuranceContextProvider>
      <ProfileContextProvider>
        <ThreeBoxContextProvider>{children}</ThreeBoxContextProvider>
      </ProfileContextProvider>
    </InsuranceContextProvider>
  )
}

function Updaters() {
  return (
    <>
      <InsuranceContextUpdater />
    </>
  )
}

function RouterCore() {
  const { search } = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const vid = searchParams.get(MAGIC_BOOST.paramsKey)
    if (vid) {
      localStorage.setItem(MAGIC_BOOST.localStorageKey, vid)
    }
    return () => {
      if (vid) {
        localStorage.removeItem(MAGIC_BOOST.localStorageKey)
      }
    }
  }, [search])

  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route path='/:slug'>
        <Referral />
      </Route>
    </Switch>
  )
}

function Router() {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <RouterCore />
      </BrowserRouter>
    </Suspense>
  )
}

function fetcher(
  requestInfo: RequestInfo,
  requestInit: RequestInit,
): Promise<any> {
  return fetch(requestInfo, requestInit).then((res) => res.json())
}

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 15000,
        fetcher,
      }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReadOnlyProvider getLibrary={getLibrary}>
          <Web3Manager>
            <ContextProviders>
              <Updaters />
              <ThemeProvider>
                <GlobalStyle />
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Router />
                </SnackbarProvider>
              </ThemeProvider>
            </ContextProviders>
          </Web3Manager>
        </Web3ReadOnlyProvider>
      </Web3ReactProvider>
    </SWRConfig>
  )
}

export default App
