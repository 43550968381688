import styled, { DefaultTheme } from 'styled-components'

const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  letter-spacing: 1px;
  color: ${({ theme, error }: { theme: DefaultTheme, error?: boolean }) =>
    error ? theme.colors.error : theme.colors.textColor};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export default Text
