import { ethers } from 'ethers'

export const NetworkContextName = 'NetworkContextName'
export const BOX_SPACE = '3fmutual'
export const TargetAsset = 'DAI'

type ChainId = 1

export type Addresses = {
  [chainId in number]: string
}

// DAI version
export const INSURANCE_ADDRESSES: Addresses = {
  1: '0x66be1bc6C6aF47900BBD4F3711801bE6C2c6CB32',
}

// SAI version
// export const INSURANCE_ADDRESSES: Addresses = {
//   1: '0x3bc058D0958a543406D70416a810a5Cc023f1F31',
// }

export const insuranceRatesByDay = [
  '0',
  '1000000000000000000',
  '1990000000000000000',
  '2970100000000000000',
  '3940399000000000000',
  '4900995010000000000',
  '5851985059900000000',
  '6793465209301000000',
  '7725530557207990000',
  '8648275251635910100',
  '9561792499119550999',
  '10466174574128355489',
  '11361512828387071934',
  '12247897700103201215',
  '13125418723102169203',
  '13994164535871147511',
  '14854222890512436036',
  '15705680661607311676',
  '16548623854991238559',
  '17383137616441326173',
  '18209306240276912911',
  '19027213177874143782',
  '19836941046095402344',
  '20638571635634448321',
  '21432185919278103838',
  '22217864060085322800',
  '22995685419484469572',
  '23765728565289624876',
  '24528071279636728627',
  '25282790566840361341',
  '26029962661171957728',
  '26769663034560238151',
  '27501966404214635769',
  '28226946740172489411',
  '28944677272770764517',
  '29655230500043056872',
  '30358678195042626303',
  '31055091413092200040',
  '31744540498961278040',
  '32427095093971665260',
  '33102824143031948607',
  '33771795901601629121',
  '34434077942585612830',
  '35089737163159756702',
  '35738839791528159135',
  '36381451393612877544',
  '37017636879676748769',
  '37647460510879981281',
  '38270985905771181468',
  '38888276046713469653',
  '39499393286246334956',
  '40104399353383871606',
  '40703355359850032890',
  '41296321806251532561',
  '41883358588189017235',
  '42464525002307127063',
  '43039879752284055792',
  '43609480954761215234',
  '44173386145213603082',
  '44731652283761467051',
  '45284335760923852380',
  '45831492403314613856',
  '46373177479281467717',
  '46909445704488653040',
  '47440351247443766510',
  '47965947734969328845',
  '48486288257619635557',
  '49001425375043439201',
  '49511411121293004809',
  '50016297010080074761',
  '50516134039979274013',
  '51010972699579481273',
  '51500862972583686460',
  '51985854342857849595',
  '52465995799429271099',
  '52941335841434978388',
  '53411922483020628604',
  '53877803258190422318',
  '54339025225608518095',
  '54795634973352432914',
  '55247678623618908585',
  '55695201837382719499',
  '56138249819008892304',
  '56576867320818803381',
  '57011098647610615347',
  '57440987661134509194',
  '57866577784523164102',
  '58287912006677932461',
  '58705032886611153136',
  '59117982557745041605',
  '59526802732167591189',
  '59931534704845915277',
  '60332219357797456124',
  '60728897164219481563',
  '61121608192577286747',
  '61510392110651513880',
  '61895288189544998741',
  '62276335307649548754',
  '62653571954573053266',
  '63027036235027322733',
  '63396765872677049506',
]

export const GAS_MARGIN = ethers.BigNumber.from(2000)
