import debounce from 'lodash.debounce'

export const paramsKey = 'vid'
export const localStorageKey = 'magic_boost'
export const baseUrl = 'https://magic.lol/4bbad3f1'

export enum ActionCodes {
  CONNECT_WALLET = '3', // not purchase target
  PURCHASE = '7', // Purchase
}

const notifyMagicBoostAction: (
  actionCode: ActionCodes,
) => Promise<Response | void> = (actionCode) => {
  const vid = localStorage.getItem(localStorageKey)

  if (vid) {
    return fetch(`${baseUrl}/brokers/pixel?action=${actionCode}&vid=${vid}`)
      .then(() => {
        console.info('request sent to magic boost')
      })
      .catch((err) => {
        console.error('Failed to register event', err)
      })
  }
  return Promise.resolve()
}

export const debounceNotifyMagicBoostAction = debounce(
  notifyMagicBoostAction,
  500,
)
