import React, { useEffect, useRef } from 'react'

type ClickAwayListenerProps = {
  onClickAway: () => void
  mouseEvent?: string
  touchEvent?: string
  children: React.ReactNode
}

export default function ClickAwayListener({
  onClickAway,
  mouseEvent = 'click',
  touchEvent = 'touchend',
  children,
}: ClickAwayListenerProps): JSX.Element {
  let node = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const handleEvents = (event: Event): any => {
      if (node.current && node.current.contains(event.target as Node)) {
        return
      }

      onClickAway()
    }

    document.addEventListener(mouseEvent, handleEvents)
    document.addEventListener(touchEvent, handleEvents)

    return () => {
      document.removeEventListener(mouseEvent, handleEvents)
      document.removeEventListener(touchEvent, handleEvents)
    }
  })

  return <div ref={node}>{children}</div>
}
