import React from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  useEagerConnect,
  useInactiveListener,
  useReadOnlyConnect,
} from '../hooks/ethereum'

type Web3ManagerProps = {
  children: React.ReactNode
}

export default function Web3Manager(props: Web3ManagerProps): JSX.Element {
  const { children } = props
  const { active, error } = useWeb3React()

  const triedEager = useEagerConnect()
  useReadOnlyConnect(triedEager)
  useInactiveListener(!triedEager)

  if (!active && error instanceof UnsupportedChainIdError) {
    return <h1>Wrong Network</h1>
  } else {
    return <>{children}</>
  }
}
