import React, { useEffect } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { useTranslation } from 'react-i18next'
import { useWeb3React } from '../hooks/ethereum'
import { ChainId, getEtherscanLink } from '../utils'
import { INSURANCE_ADDRESSES } from '../constants'

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`

const Link = styled.a`
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.buttonBackground};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const Select = styled.select`
  height: 2rem;
  padding: 0 0.5rem;
  border: 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => lighten(0.4, theme.colors.buttonBackground)};
  font-size: 0.875rem;
`

export default function Footer(): JSX.Element {
  const { chainId } = useWeb3React()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (i18n.language === 'zh') {
      i18n.changeLanguage('zh-TW')
    }
  }, [i18n])

  return (
    <footer>
      <Row>
        <Select
          value={i18n.language}
          onChange={(event) => {
            i18n.changeLanguage(event.currentTarget.value)
          }}
        >
          <option value='en-US'>English</option>
          <option value='zh-TW'>正體中文</option>
          <option value='zh-CN'>简体中文</option>
        </Select>
      </Row>
      <Row>
        <Link
          href={getEtherscanLink(chainId as ChainId, INSURANCE_ADDRESSES[chainId as number], 'address')}
          target='_blank'
        >
          Contract Adddress
        </Link>
        <Link href='https://youtu.be/i-yJg1X4Brw' target='_blank'>
          Tutorial
        </Link>
        <Link href='https://hakka.finance/' target='_blank'>
          Hakka Finance
        </Link>
        <Link href='https://medium.com/hakkafinance/defi-products/home' target='_blank'>
          Medium
        </Link>
        <Link href='https://discord.gg/cU4D2a8' target='_blank'>
          Discord
        </Link>
      </Row>
    </footer>
  )
}
