import { ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { parseUnits, formatUnits } from '@ethersproject/units'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'

export type ChainId = 1 | 3 | 4 | 5

export function delay(ms: number) {
  return new Promise((res) => setTimeout(res, ms))
}

export function safeAccess(object: any, path: Array<string | number>) {
  return object
    ? path.reduce(
        (accumulator: any, currentValue: string | number) =>
          accumulator && accumulator[currentValue]
            ? accumulator[currentValue]
            : null,
        object,
      )
    : null
}

export function parseQueryString(queryString: string) {
  const query = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    ;(query as any)[decodeURIComponent(pair[0])] = decodeURIComponent(
      pair[1] || '',
    )
  }
  return query
}

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function shortenAddress(address: string, digits: number = 4): string {
  if (!isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${address.substring(0, digits + 2)}...${address.substring(
    42 - digits,
  )}`
}

export function shortenTransactionHash(
  hash: string,
  digits: number = 6,
): string {
  return `${hash.substring(0, digits + 2)}...${hash.substring(66 - digits)}`
}

export function getNetworkName(chainId: ChainId): string {
  switch (chainId) {
    case 1: {
      return 'Main Network'
    }
    case 3: {
      return 'Ropsten'
    }
    case 4: {
      return 'Rinkeby'
    }
    case 5: {
      return 'Görli'
    }
    default: {
      return 'correct network'
    }
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'address',
): string {
  const prefix = `https://${
    ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
  }etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export async function getGasPrice(level: string): Promise<BigNumber> {
  const response = await fetch('https://ethgasstation.info/json/ethgasAPI.json')
  const data = await response.json()
  const gasPrice = BigNumber.from(data[level].toString()).div(10).mul(1e9) // convert unit to wei
  return gasPrice
}

export function calculateGasMargin(value: BigNumber, margin: BigNumber) {
  const offset = value.mul(margin).div(ethers.BigNumber.from(10000))
  return value.add(offset)
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string,
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any,
  )
}

export function amountFormatter(
  amount: BigNumber,
  baseDecimals: number,
  displayDecimals: number = 4,
): string {
  if (
    baseDecimals > 18 ||
    displayDecimals > 18 ||
    displayDecimals > baseDecimals
  ) {
    throw Error(
      `Invalid combination of baseDecimals '${baseDecimals}' and displayDecimals '${displayDecimals}.`,
    )
  }

  if (amount.isZero()) {
    return '0'
  }

  const amountDecimals = 18 - amount.toString().length + 1

  return parseFloat(formatUnits(amount, baseDecimals)).toFixed(
    amountDecimals >= displayDecimals ? amountDecimals + 1 : displayDecimals,
  )
}

export function percentageFormatter(
  amount: BigNumber,
  baseDecimals: number,
  displayDecimals: number = 2,
): string {
  if (
    baseDecimals > 18 ||
    displayDecimals > 18 ||
    displayDecimals > baseDecimals
  ) {
    throw Error(
      `Invalid combination of baseDecimals '${baseDecimals}' and displayDecimals '${displayDecimals}.`,
    )
  }

  if (amount.isZero()) {
    return '0'
  }

  return `${amount
    .div(parseUnits('1', baseDecimals))
    .mul(parseUnits('1', 2))
    .toNumber()
    .toFixed(displayDecimals)} %`
}

export function validateEmail(email: string): boolean {
  var re =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function leftPad(s: number | string) {
  return s.toString().padStart(2, '0')
}

export function formatUTC0(
  timestamp: number = 0,
  hideTime: boolean = false,
): string {
  const date = new Date(timestamp)

  if (hideTime) {
    return `${date.getUTCFullYear()}/${leftPad(
      date.getUTCMonth() + 1,
    )}/${leftPad(date.getUTCDate())}`
  }

  return `${date.getUTCFullYear()}/${leftPad(date.getUTCMonth() + 1)}/${leftPad(
    date.getUTCDate(),
  )} ${leftPad(date.getUTCHours())}:${leftPad(date.getUTCMinutes())}:${leftPad(
    date.getUTCSeconds(),
  )} (UTC+0)`
}
