import React from 'react'

type TabPanelProps = {
  value: number | string
  index: number | string
  children: React.ReactNode
}

export default function TabPanel(props: TabPanelProps): JSX.Element {
  const { value, index, children } = props


  return (
    <div>
      {value === index && children}
    </div>
  )
}
