import React, { useState, useEffect, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled, { DefaultTheme } from 'styled-components'
import { transparentize } from 'polished'
import ClickAwayListener from './ClickAwayListener'
import { injected, walletconnect } from '../connectors'
import { shortenAddress, getNetworkName, ChainId } from '../utils'
import {
  ActionCodes,
  debounceNotifyMagicBoostAction,
} from '../utils/magicBoost'

const Button = styled.button`
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  padding: 0 1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.8, theme.colors.white)};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.colors.indicator};
  }
`

const MenuWrapper = styled.div`
  position: relative;
`

const Menu = styled.div`
  position: absolute;
  right: 0;
  top: 3rem;
  width: 12rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'block' : 'none')};
`

const MenuHeader = styled.div`
  height: 2.5rem;
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: 0;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.95, theme.colors.black)};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.colors.indicator};
  }

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`

const ConnectorIndicator = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({
    theme,
    active,
  }: {
    theme: DefaultTheme
    active: boolean
  }) => (active ? theme.colors.success : theme.colors.error)};
`

export default function Web3Status() {
  const { active, account, library, chainId, connector, activate, deactivate } =
    useWeb3React()
  const [ensName, setEnsName] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const wrapActivate: typeof activate = useCallback(
    (...params) => {
      debounceNotifyMagicBoostAction(ActionCodes.CONNECT_WALLET)
      return activate(...params)
    },
    [activate],
  )

  const isConnectedMetaMask = connector === injected
  const isConnectedWalletconnect = connector === walletconnect

  useEffect(() => {
    if (account) {
      if (chainId === 1) {
        library.lookupAddress(account).then((ens: string) => setEnsName(ens))
      }
    }
  }, [library, chainId, account])

  const connectMetaMask = useCallback(async () => {
    if (!isConnectedMetaMask) {
      await wrapActivate(injected)
    }
    setIsOpen(false)
  }, [wrapActivate, isConnectedMetaMask])

  const connectWalletconnect = useCallback(async () => {
    if (!isConnectedWalletconnect) {
      await wrapActivate(walletconnect)
    }
    setIsOpen(false)
  }, [wrapActivate, isConnectedWalletconnect])

  const disconnect = useCallback(() => {
    deactivate()
    setIsOpen(false)
  }, [deactivate])

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <MenuWrapper>
        <Button onClick={() => setIsOpen(!isOpen)}>
          {active ? ensName || shortenAddress(account as string) : 'Connect'}
        </Button>
        <Menu isOpen={isOpen}>
          {active && (
            <MenuHeader>{getNetworkName(chainId as ChainId)}</MenuHeader>
          )}
          <MenuItem onClick={connectMetaMask}>
            <ConnectorIndicator active={isConnectedMetaMask} />
            <span>MetaMask</span>
          </MenuItem>
          <MenuItem onClick={connectWalletconnect}>
            <ConnectorIndicator active={isConnectedWalletconnect} />
            <span>Walletconnect</span>
          </MenuItem>
          {active && <MenuItem onClick={disconnect}>Disconnect</MenuItem>}
        </Menu>
      </MenuWrapper>
    </ClickAwayListener>
  )
}
