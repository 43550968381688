import React from 'react'
import styled from 'styled-components'

const InputField = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-size: 1rem;
`

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: ${({ height }: { height?: string | number | undefined }) => height || '3rem'};
  border: 2px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  display: flex;
  align-items: center;
`

interface TextFieldProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
}

export default function TextField(props: TextFieldProps): JSX.Element {
  const { label = '', ...inputProps } = props

  return (
    <InputField>
      <Label>{label}</Label>
      <TextArea {...inputProps} />
    </InputField>
  )
}
